<template>
    <div class="d-flex flex-column">
        <div class="view-container">
            <video
                class="video-fluid"
                ref="audioVideoPlayer"
                v-if="!media.poster"
            ></video>
            <audio ref="audioVideoPlayer" v-else hidden></audio>
            <Attachment v-if="media.poster" :media="media.poster"></Attachment>
            <!-- <img class="img-fluid" v-if="media.poster" :src="media.poster"/> -->
            <!-- <img
                v-if="media.poster"
                :src="media.poster"
            /> -->
        </div>
        <div class="player-controls">
            <v-btn
                outlined
                fab
                small
                color="primary"
                class="mr-5"
                :disabled="!isReady"
                @click.stop="changePosition(-5)"
                ><v-icon>mdi-step-backward</v-icon>
            </v-btn>
            <v-btn
                outlined
                fab
                @click="togglePlayer"
                color="secondary"
                class="ma-5"
                ><v-icon>{{
                    playing ? "mdi-pause" : "mdi-play"
                }}</v-icon>
            </v-btn>
            <v-btn
                outlined
                fab
                small
                color="primary"
                class="ml-5"
                :disabled="!isReady"
                @click.stop="changePosition(5)"
            >
                <v-icon>mdi-step-forward</v-icon>
            </v-btn>
        </div>
        <v-row class="player-tracks">
            <v-col cols="9">
                <v-slider
                    class="ma-2"
                    :disabled="!isReady"
                    ref="slider"
                    v-model="position"
                    color="primary"
                    :max="duration * 1000"
                    min="0"
                    :label="
                        `${formatTime(position / 1000)}/${formatTime(duration)}`
                    "
                    @click.stop="updatePosition()"
                >
                </v-slider>
            </v-col>

            <v-col cols="3">
                <v-slider v-model="volume" max="1" min="0" step="0.1">
                    <!-- <template v-slot:prepend>
                        <v-icon @click.stop="setVolume(0)">mdi-volume-mute</v-icon>
                    </template> -->

                    <template v-slot:prepend>
                        <v-icon @click.stop="setVolume(1)">mdi-volume-high</v-icon>
                    </template>
                </v-slider>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export const Attachment = () => import("./Attachment");

export default {
    props: {
        media: {
            type: Object,
            required: true
        }
    },

    data: () => ({
        value: 1,
        position: 0,
        htmlPlayer: null,
        ready: false,
        source: null,
        playing: false,
        volume: 0.5,
        recoverPlay: false,
        duration: 0,
        isReady: false
    }),

    components: {
        Attachment
    },

    watch: {
        volume: function(newVal) {
            this.htmlPlayer.volume = newVal;
        }
    },

    /*     watch: {
        getPosition: function(newVal, oldVal) {
            this.position = newVal * 1000;
        }
    }, */

    computed: {
        /*         ...mapState({
            appFooterColor: state => state.appModule.appFooterColor,
            containerHeight: state => state.appModule.playerHeight,
            getVolume: state => state.playerModule.volume,
            playStatus: state => state.playerModule.playing,
            isReady: state => state.playerModule.ready,
            getPosition: state => state.playerModule.position,
            getDuration: state => state.playerModule.duration
        }), */
        /*         volume: {
            get: function() {
                return this.getVolume;
            },
            set: function(vol) {
                this.setVolume(vol);
            }
        } */
    },

    methods: {
        /*         ...mapActions({
            createPlayer: playerActions.PLAYER_CREATE
        }),

        ...mapMutations({
            setVolume: playerActions.PLAYER_SET_VOLUME,
            setPosition: playerActions.PLAYER_SET_POSITION,
            playMedia: playerActions.PLAYER_PLAY_MEDIA,
            togglePlay: playerActions.PLAYER_TOGGLE_PLAY,
            resetPlayer: playerActions.PLAYER_RESET
        }), */

        updatePosition() {
            this.setPosition(this.position / 1000);
        },

        togglePlayer() {
            console.log("toggle");
            this.togglePlay();
            // this.playMedia('https://localhost:5001/Audio/audiotrack/346161921ed45d2608b31a2073d09ffc')
            // this.playMedia('http://streaming.mwave.it/coop')
        },
        formatTime(second) {
            return new Date(second * 1000).toISOString().substr(14, 5);
        },

        changePosition(step) {
            this.setPosition(step + this.position / 1000);
        },
        /**** */
        togglePlay() {
            if (this.htmlPlayer.paused) {
                this.htmlPlayer.play();
            } else {
                this.htmlPlayer.pause();
            }
        },

        setVolume(volume) {
            this.volume = volume;
        },

        setPosition(position) {
            this.htmlPlayer.currentTime = position;
        },

        onCanPlay() {
            this.htmlPlayer.removeEventListener(
                "canplaythrough",
                this.onCanPlay,
                false
            );
            this.htmlPlayer.removeEventListener("load", this.onCanPlay, false);

            this.htmlPlayer.currentTime = 0;
            this.isReady = true;
            this.htmlPlayer.play();
        },

        playMedia(media) {
            this.htmlPlayer.src = media;
            this.htmlPlayer.play();
            console.log("stato player html", this.htmlPlayer.readythis);
            if (this.htmlPlayer.readythis !== 4) {
                this.isReady = false;
                this.htmlPlayer.addEventListener(
                    "canplaythrough",
                    this.onCanPlay,
                    false
                );
                this.htmlPlayer.addEventListener("load", this.onCanPlay, false); //add load event as well to avoid errors, sometimes 'canplaythrough' won't dispatch.
            }
        },

        resetPlayer() {
            if (!this.htmlPlayer.paused) {
                this.htmlPlayer.pause();
            }
        }
    },

    beforeDestroy() {
        this.resetPlayer();
    },

    mounted() {
        this.$nextTick(function() {
            this.htmlPlayer = this.$refs.audioVideoPlayer;
            this.htmlPlayer.volume = this.volume;

            this.htmlPlayer.ondurationchange = () => {
                this.duration = this.htmlPlayer.duration;
                console.log("on durationchange", this.duration);
            };

            this.htmlPlayer.onplay = () => {
                console.log("on play");
                this.playing = true;
            };

            this.htmlPlayer.onplaying = () => {
                // console.log('on playing');
            };
            // in caco di timeupdate aggiorno la percentuale in base
            // alla posizione corrente
            this.htmlPlayer.ontimeupdate = () => {
                this.position = this.htmlPlayer.currentTime * 1000;
                // console.log(this.position)
                // console.log(this.position, this.htmlPlayer.currentTime, this.htmlPlayer.duration)
            };

            this.htmlPlayer.onpause = () => {
                console.log("on pause");
                this.playing = false;
            };

            this.htmlPlayer.onended = () => {
                // alla fine del brano chiamo una funzione esterna, definita nel controller
                // che ha instanziato la direttiva con lo scopo di cancellare tutte le informazioni
                // del tag audio dalla pagina
                // vm.clearAudio();
                console.log("on ended");
            };

            this.htmlPlayer.onerror = e => {
                console.error(e);
                /*dispatch(snackbarActions.SNACKBAR_SHOW, e)*/
            };

            this.playMedia(this.media.src);

            /*console.log(this.htmlPlayer);
            this.createPlayer(this.htmlPlayer).then(() => {
                this.playMedia(this.media.src);
            }); */
        });
    }
};
</script>

<style>
.view-container {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    justify-content: center;
}

.video-fluid {
    max-width: 100%;
    height: auto;
}

.player-controls {
    display: flex;
    align-items: center;
    justify-content: center;
}

.player-tracks {
    align-items: center;
}
</style>
